import React from 'react';
import "../../../App.css";
import MySkills from "./MySkills";
import "./skills.css"

const Skills = () => {
    return (
        <div className='skills' id="SkillScroll">
            <h1 >Skills</h1>
            <MySkills />
        </div>
    )
}
export default Skills;